<!--
 * @Description: 场景详情
 * @Author: ChenXueLin
 * @Date: 2021-10-18 15:42:12
 * @LastEditTime: 2022-04-20 15:15:03
 * @LastEditors: LiangYiNing
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <div class="detailInfo-content">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="基本资料" name="basicInfo">
                <basic-info ref="basicInfo"></basic-info>
              </el-tab-pane>
              <el-tab-pane label="安装照片" name="installPhoto">
                <install-photo ref="installPhoto"></install-photo>
              </el-tab-pane>
              <el-tab-pane label="设备数据查询" name="deviceData">
                <device-data ref="deviceData"></device-data>
              </el-tab-pane>
              <el-tab-pane label="对接平台信息" name="dockingPlatform">
                <docking-platform ref="dockingPlatform"></docking-platform>
              </el-tab-pane>
              <el-tab-pane label="属性配置" name="attrConfig">
                <attr-config ref="attrConfig"></attr-config>
              </el-tab-pane>
              <el-tab-pane label="条目信息" name="feeInfo">
                <fee-info ref="feeInfo"></fee-info>
              </el-tab-pane>
              <el-tab-pane label="关联单据" name="bills">
                <bills ref="bills"></bills>
              </el-tab-pane>
              <el-tab-pane label="维修记录" name="maintenanceRecord">
                <maintenance-record
                  ref="maintenanceRecord"
                ></maintenance-record>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 基本信息页面 -->
        </div>
      </section>
      <!-- 表格 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import BasicInfo from "./basicInfo.vue";
import InstallPhoto from "./installPhoto.vue";
import deviceData from "./deviceData.vue";
import DockingPlatform from "./dockingPlatform.vue";
import Bills from "./bills.vue";
import AttrConfig from "./attrConfig.vue";
import feeInfo from "./feeInfo.vue";
import maintenanceRecord from "./maintenanceRecord.vue";
export default {
  name: "SceneInfoDetail",
  data() {
    return {
      activeName: "basicInfo",
      vehicleId: "",
      thirdClassName: "", // 三级分类名称
      sceneId: "", // 场景id
      terminalId: "" // 终端id
    };
  },
  mixins: [base],
  components: {
    BasicInfo,
    InstallPhoto,
    deviceData,
    DockingPlatform,
    Bills,
    AttrConfig,
    feeInfo,
    maintenanceRecord
  },
  created() {
    this.initForm();
  },
  mounted() {},
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.vehicleId = hasParamsRoute.params.vehicleId;
      this.terminalId = hasParamsRoute.params.terminalId;
      this.thirdClassName = hasParamsRoute.params.thirdClassName;
      this.sceneId = hasParamsRoute.params.sceneId;
      this.activeName = "basicInfo";
      if (this.vehicleId) {
        this.$nextTick(() => {
          this.$refs.basicInfo.init(this.vehicleId);
        });
      }
    },
    getBaseInfo() {},
    handleClick() {
      switch (this.activeName) {
        case "basicInfo":
          this.$refs.basicInfo.init(this.vehicleId);
          break;
        case "installPhoto":
          this.$refs.installPhoto.init(this.vehicleId);
          break;
        case "deviceData":
          this.$refs.deviceData.init(this.sceneId);
          break;
        case "dockingPlatform":
          this.$refs.dockingPlatform.init(this.vehicleId);
          break;
        case "attrConfig":
          this.$refs.attrConfig.init(this.terminalId, this.thirdClassName);
          break;
        case "feeInfo":
          this.$refs.feeInfo.init(this.terminalId);
          break;
        case "bills":
          this.$refs.bills.init(this.terminalId);
          break;
        case "maintenanceRecord":
          this.$refs.maintenanceRecord.init(this.terminalId);
          break;
      }
    },
    //创建任务单
    createTask() {
      this.routerPush({
        name: "workOrderManage/createTask",
        params: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.main-wrapper > .container-wrapper .content-view-wrapper .content-view {
  padding: 0px;
}
.main-wrapper .edit-wrapper .edit-wrapper__body {
  height: 100%;
  position: relative;
  /deep/.detailInfo-content {
    height: 100%;
    .el-tabs {
      height: 100%;
      .el-tabs__content {
        overflow: scroll;
        height: calc(100% - 55px);
        .el-tab-pane {
          height: calc(100% - 2px);
        }
      }
    }
  }
}
</style>
